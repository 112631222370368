<script setup lang="ts">
// import type { ParsedContent } from '@nuxt/content/dist/runtime/types';
import DashboardPanel from '@nuxt/ui-pro/components/dashboard/DashboardPanel.vue';

const { data: navigation } = await useAsyncData('navigation', () => fetchContentNavigation(), { default: () => [] });
// const { data: files } = useLazyFetch<ParsedContent[]>('/api/search.json', { default: () => [], server: false });

provide('navigation', navigation);
</script>

<template>
    <div class="bg-[#FFFAED]">
        <div class="flex mx-auto flex-col w-full min-w-0 max-w-[1080px]">
            <div class="flex-col mx-auto items-stretch relative w-full flex-1 flex">
                <div class="sticky top-0 z-50 flex-shrink-0 flex min-w-0">
                    <Header />
                </div>

                <UMain class="flex-1 flex flex-col">
                    <slot />
                </UMain>
            </div>

            <Footer />
        </div>

        <ClientOnly>
            <LazyUContentSearch :navigation="navigation" />
        </ClientOnly>
    </div>
</template>
