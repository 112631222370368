<script setup lang="ts">
import type { NavItem } from '@nuxt/content/dist/runtime/types';

const navigation = inject<Ref<NavItem[]>>('navigation', ref([]));

const { isAuthenticated } = useAuth();
const user = useUser();

const links = [
    // {
    //     label: 'Products',
    //     to: '/products',
    // },
    {
        label: 'Sports',
        to: '/sports',
    },
    //  {
    //   label: 'Pricing',
    //   to: '/pricing'
    // },
    {
        label: 'Our story',
        to: '/about',
    },
];
</script>

<template>
    <UHeader class="border-0 w-full bg-transparent" :links="links">
        <template #logo>
            <div class="w-36">
                <CommonLogo />
            </div>
        </template>

        <template #right>
            <!-- <UButton
                class="hidden lg:flex"
                to="/scores"
                color="red"
                variant="outline"
                leadingIcon="i-heroicons-play-circle-solid"
                >Live Scores</UButton
            > -->

            <!-- <template v-if="isAuthenticated">
                <UButton label="Go to app" to="/app" color="black">
                    <template #leading>
                        <UAvatar :src="user?.image" size="2xs" />
                    </template>
                </UButton>
            </template>
            <template v-else>
                <UButton label="Sign in" color="gray" to="/auth/sign-in" />
                <UButton
                    label="Sign up"
                    icon="i-heroicons-arrow-right-20-solid"
                    trailing
                    color="black"
                    to="/auth/sign-up"
                    class="hidden lg:flex"
                />
            </template> -->
        </template>

        <template #panel>
            <!-- <UNavigationTree :links="mapContentNavigation(navigation)" default-open /> -->
        </template>
    </UHeader>
</template>
