<template>
    <footer class="flex m-auto w-full py-10 mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto w-full">
            <div class="pt-4 border-t border-gray-900/10 md:flex md:items-center md:justify-between">
                <div class="text-xs flex flex-col gap-2 leading-5 md:mt-0">
                    <CommonLogo class="w-20" />
                    <p class="capitalize text-gray-500">&copy; 2024 {{ $t('name') }} All rights reserved.</p>
                    <div class="flex text-xs mt-2 gap-1 items-center text-neutral-400">
                        <span class="font-medium">Built in Brisbane, Australia</span>
                        <UColorModeButton size="lg" />
                    </div>
                </div>

                <div class="flex flex-col py-2 md:py-0 order-2">
                    <div class="flex gap-2">
                        <div class="" v-for="item in navigation.legal">
                            <NuxtLink class="text-xs font-medium" :to="item.href">{{ item.name }}</NuxtLink>
                        </div>
                    </div>
                </div>

                <div class="pb-14 md:pb-0 flex pt-4 md:pt-0 space-x-6 md:order-2 order-3">
                    <a
                        v-for="item in navigation.social"
                        :key="item.name"
                        :href="item.href"
                        class="text-gray-400 hover:text-gray-500"
                    >
                        <span class="sr-only">{{ item.name }}</span>
                        <UIcon :name="item.icon" class="h-6 w-6" aria-hidden="true" />
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
const { locale } = useI18n();

const locals = [
    {
        name: 'English',
        value: 'en',
    },
    {
        name: 'German',
        value: 'de',
    },
];

const navigation = {
    legal: [
        // { name: 'Privacy', href: '/privacy' },
        // { name: 'Terms', href: '/terms' },
    ],
    social: [
        // {
        //     name: 'Facebook',
        //     href: '#',
        //     icon: 'i-ri-facebook-box-fill',
        // },
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/arenachamp_/',
            icon: 'i-ri-instagram-line',
        },
        // {
        //     name: 'X',
        //     href: 'https://x.com/arenachampapp',
        //     icon: 'i-ri-twitter-x-fill',
        // },
        // {
        //     name: 'YouTube',
        //     href: 'https://www.youtube.com/channel/UCJ1Jj6J9J1Jj6J9J1Jj6J9J1',
        //     icon: 'i-ri-youtube-fill',
        // },
    ],
};
</script>
